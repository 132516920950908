@import '../../style/abstract/variables';

@import '../../style/abstract/media';
@import '../../style/abstract/mixins';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '../../style/abstract/button';
@import '../../style/abstract/placeholder';

:root {
    --checkout-order-summary-scroll-accent-color: #{$default-secondary-base-color};
}

.Checkout {
    &-Wrapper {
        padding-left: var(--content-padding);
        padding-right: var(--content-padding);
        .ExpandableContent.CartPage-Discount:last-of-type {
            border: 1px solid #e7e7e7;
            margin: 65px 0 0;
            @include desktop {
                margin: 16px 0 0;
            }
        }
    }

    &-ExtraInfo {
        @include desktop {
            padding-left: 16px;
            padding-right: 16px;
        }
    }

    .AdvoxFreeDelivery {
        padding: 0;
        margin: 17px 0;

        svg {
            margin-left: 17px;
            @include desktop {
                margin-left: 32px;
            }
        }

        @include desktop {
            margin: 17px 0;
        }
    }

    &-Heading,
    &-Title {
        font-family: $font-primary;
        font-weight: bold;
        color: $default-primary-base-color;
    }

    &-Heading {
        font-size: 22px;
        line-height: 135%;
        letter-spacing: 0.07em;
        margin: 0 0 20px;
    }

    &-Title {
        font-size: 22px;
        line-height: 135%;
        letter-spacing: 0.07em;

        @include desktop {
            font-size: 30px;
            line-height: 140%;
            letter-spacing: 0.03em;
        }
    }

    .CartItem-CartItemRows {
        justify-content: flex-start;
    }

    .CheckoutOrderSummary .CartItem-Wrapper {
        padding: 18px 0;
        min-height: unset;
        @include desktop {
            grid-template-columns: minmax(100px, 147px) 1fr;
        }
        .ProductPrice {
            font-size: 15px;
            line-height: 26px;
        }
        .CartItem-ProductActions {
            justify-content: space-between;
            margin-top: 5px;
        }
        .CartItem-Heading {
            @include desktop {
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                overflow: hidden;
                white-space: initial;
                text-overflow: initial;
            }
        }
    }

    .ExpandableContent:last-of-type {
        border: 0;

        .CheckoutOrderSummary-OrderTotals {
            display: none;
        }
    }

    .CheckoutOrderSummary-CartItemList {
        max-height: 328px;
        overflow-y: auto;

        @include desktop {
            margin: 20px 0 32px;
        }

        &::-webkit-scrollbar {
            width: 20px;
        }

        &::-webkit-scrollbar-thumb {
            background-color: var(--checkout-order-summary-scroll-accent-color);
            border-left: 15px solid $white;
        }

        &::-webkit-scrollbar-track {
            background-color: $grey5;
            border-left: 15px solid $white;
        }

        .CartItem {
            &-Title {
                width: 100%;
            }
            &:last-of-type {
                border-bottom: 0;
            }
        }
    }

    &-StickyButtonWrapper {
        @include mobileAndTablet {
            border-block-start: 1px solid var(--primary-divider-color);
            position: fixed;
            inset-inline-start: 0;
            width: 100%;
            z-index: 85;
            inset-block-end: var(--footer-total-height);
            background-color: var(--color-white);
            padding: 16px;
        }

        .Button {
            @include mobileAndTablet {
                width: 100%;
            }

            svg {
                margin-left: 16px;
                width: 14px;
                height: 14px;

                path {
                    stroke: $white;
                }
            }

            &:disabled {
                svg path {
                    stroke: var(--button-disabled-color);
                }
            }
        }
        &.CheckoutOrderSummary-ButtonWrapper {
            display: block;
            bottom: 0;
            @include desktop {
                display: none;
            }
            .CheckoutShipping-Button {
                font-family: $font-primary;
                letter-spacing: 0.07em;
                font-weight: bold;
                font-size: 15px;
                line-height: 140%;
                margin: 25px 0 0;
                width: 100%;
                text-align: center;
            }
        }
    }

    .CheckoutSteps {
        @include mobileAndTablet {
            padding-bottom: 24px;
        }

        @include desktop {
            margin-bottom: 54px;
        }
    }

    .CartPage-CheckoutButton.Button {
        width: 100%;
        font-family: $font-primary;
        letter-spacing: 0.07em;
        font-weight: bold;
        font-size: 15px;
        line-height: 140%;
        margin: 25px 0 0;
        svg {
            margin-left: 16px;
            width: 14px;
            height: 14px;

            path {
                stroke: $white;
            }
        }

        &[disabled] {
            border-color: var(--button-disabled-color);

            svg {
                path {
                    stroke: var(--button-disabled-color);
                }
            }
        }
    }

    &-Summary {
        position: sticky;
        top: 65px;
        padding-bottom: 24px;
        align-self: start;
    }

    &-Header {
        &_checkoutStep_DETAILS_STEP {
            @include tablet {
                margin-top: 80px;
            }
        }
    }
}
