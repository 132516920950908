@import '../../style/abstract/variables';

@import '../../style/abstract/media';
@import '../../style/abstract/mixins';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '../../style/abstract/button';
@import '../../style/abstract/placeholder';

.CheckoutNewsletter {
    display: block;
    width: 100%;
    > div {
        display: flex;
        width: 100%;
        margin-bottom: 24px;

        .Field {
            margin-top: 0;
        }

        label {
            order: 2;
            > * {
                display: inline;
            }
        }
    }

    .Field-Message {
        display: block;
        width: 100%;
    }
}
